import React from "react";
import { Header } from ".";
import './Wrapper.css';

function Wrapper(props) {
    return (
        <div className='wrapper'>
            {props.children}
        </div>
    )
}

export default Wrapper;