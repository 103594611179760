import React from "react";
import "./components/Jumbotron.css";
import { Container, Wrapper } from "./components";
import Jumbotron from "./components/Jumbotron";

function Home() {
    return (
        <div className='Home'>
            <Wrapper>
                    <Jumbotron />
                    <div className="section">
                        <h3>
                            Small Business? Freelancer? Growing Company? 
                        </h3>
                        <p>
                            At Digital Wrench Media, we use modern tools and technologies to boost your business' image and presence on the web. 
                        </p>
                    </div>
            </Wrapper>
        </div>
    )
}

export default Home;