import React from "react";
import './Navbar.css';
import { Link, useLocation } from "react-router-dom";

function toggleMenu() {
    let menu = document.getElementById('hamburger-menu');
    if (menu.style.visibility == 'visible')
    {
        menu.style.transition = 'none';
        menu.style.visibility = 'hidden';
        menu.style.opacity = 0;
    } else {
        menu.style.transition = 'visibility 0.3s ease-in, opacity 0.3s ease-in';
        menu.style.visibility = 'visible'
        menu.style.opacity = 1;
    }
}

function GetImage(navbarClass)
{
    if (navbarClass == 'navbar navbar-transparent')
    {
        return <Link to='/'><img src="/img/DWM_Small.png"/></Link>
    }
    else
    {
        return <Link to='/'><img src="/img/DWM_Small_Light.png"/></Link>
    }
}

function Navbar() {
    const location = useLocation();
    const navbarClass = location.pathname == "/" ? 'navbar navbar-transparent' : 'navbar navbar-opaque-light'
    const navHamburgerMenuClass = location.pathname == "/" ? 'nav-hamburger-menu nav-hamburger-menu-dark' : 'nav-hamburger-menu nav-hamburger-menu-light' 
    return (
        <div className='header'>
            <div className={navbarClass}>
                <div className='nav-title'>
                    {GetImage(navbarClass)}
                </div>
                <div className='nav-spacer' />

                {/* Shows when larger than tablet */}
                <div className='nav-menu'>
                    <ul className='navigation'>
                        <li className='nav-spread'></li>
                        {/* <li><Link to='/process'>Our Process</Link></li> */}
                        <li><Link to='/contact'>Get In Touch</Link></li>
                        {/* <li><Link to='/clients'>Clients</Link></li> */}
                    </ul>
                </div>

                {/* Shows when smaller than tablet */}
                <div className='nav-hamburger'>
                    <div className={'hamburger-icon '} onClick={toggleMenu}>
                        <i className="fa fa-bars"></i>
                    </div>
                </div>
                <div id='hamburger-menu' className={navHamburgerMenuClass}>
                    <div className="nav-hamburger-menu-closer">
                        <div className={'hamburger-icon '} onClick={toggleMenu}>
                            <i className="fa fa-bars"></i>
                        </div>
                    </div>
                    <div className="nav-hamburger-menu-options">
                        <Link to='/' onClick={toggleMenu}>
                            <div className={'nav-hamburger-menu-option'}>
                                <span>Home</span>
                            </div>
                        </Link>
                    
                        {/* <Link to='/process' onClick={toggleMenu}>
                            <div className={'nav-hamburger-menu-option'}>
                                <span>Our Process</span>
                            </div>
                        </Link> */}

                        <Link to='/contact' onClick={toggleMenu}>
                            <div className={'nav-hamburger-menu-option'}>
                                <span>Get In Touch</span>
                            </div>
                        </Link>

                        {/* <Link to='/clients' onClick={toggleMenu}>
                            <div className={'nav-hamburger-menu-option'}>
                                <span>Clients</span>
                            </div>
                        </Link>     */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navbar;

