import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Home, Contact, OurProcess, Clients } from './pages';
import { Footer, Navbar, Wrapper } from './pages/components';

function App() {
  return (
      <BrowserRouter>
        <Wrapper>
          <Navbar/>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/process" element={<OurProcess />} />
            <Route path="/clients" element={<Clients />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </Wrapper>
        <Footer/>
        <script src="https://kit.fontawesome.com/bc02678277.js" crossOrigin="anonymous"></script>
      </BrowserRouter>
  );
}

export default App;
