import React from "react";
import './Footer.css';
import { Link } from "react-router-dom";

function Footer() {
    return (
        <footer className='footer'>
            <div className='footer-left'>
            </div>
            <div className='footer-center'>
                <h4 className="footer-company"><span className="footer-text-bigger">D</span>igital <span className="footer-text-bigger">W</span>rench <span className="footer-text-bigger">M</span>edia</h4>
                {/* <p>
                    <Link to='/process'>Our Process</Link> 
                    <span className='footer-text-separator'> • </span> 
                    <Link to='/contact'>Get In Touch</Link> 
                    <span className='footer-text-separator'> • </span> 
                    <Link to='/clients'>Clients</Link> 
                    <span className='footer-text-separator'> • </span> 
                    <Link to='/privacy'>Privacy Policy</Link>
                </p> */}
                <p><a href='mailto:george@digitalwrenchmedia.com'><i className="far fa-envelope"></i><span className='footer-link-label'>george@digitalwrenchmedia.com</span></a></p>
            </div>
            <div className='footer-right'></div>
        </footer>
    )
}

export default Footer;