import React from "react";
import './CardCollection.css';

function CardCollection(props) {
    return (
        <div className="card-collection">
            {props.children}
        </div>
    )
}

export default CardCollection;