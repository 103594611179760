import React from "react";
import './Button.css';
import { Link } from "react-router-dom";

function Button(props) {
    if (props.destination !== undefined)
    {
        return (
            <Link to={props.destination}>
                <button className='button button-large button-blue'>{props.children}</button>
            </Link>
        )
    }
    else
    {
        return (
            <button className='button button-large button-blue'>{props.children}</button>
        )
    }
}

export default Button;