import React from "react";
import { Wrapper, Section, Button } from "./components";
import ContactForm from "./models/ContactForm";
import './Contact.css'
import { SNSClient, AddPermissionCommand, PublishCommand  } from "@aws-sdk/client-sns";

const handleSubmit = async (event) => {

    // Prevent the default submit action
    event.preventDefault();

    // Get the data from the form
    let contactForm = document.getElementById('contact-form').elements;
    let successMsg = document.getElementById('form-success');
    let failureMsg = document.getElementById('form-failure');

    let requestData = new ContactForm();
    requestData.name = contactForm.name.value;
    requestData.email = contactForm.email.value;
    requestData.phone = contactForm.phone.value;
    requestData.contactMethod = contactForm.contactMethod.value;
    requestData.serviceNeed = contactForm.serviceNeed.value;
    requestData.message = contactForm.message.value;

    let responseFromAws = await SendMessage(requestData);
    
    if (responseFromAws.PublishResponse.PublishResult.MessageId !== undefined) {
        document.getElementById('contact-form').reset();
        successMsg.style.display = 'flex';
        failureMsg.style.display = 'none';
    } else {
        failureMsg.style.display = 'flex';
        successMsg.style.display = 'none';
    }
    
}

async function SendMessage(data) {
    const url = "https://tn6mt66fhi.execute-api.us-east-1.amazonaws.com/Production"
    const attributes = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };

    const response = await fetch(url, attributes)
    .then(res => res.json());
    return response
}

function GetInTouch() {
    return (
        <div className='GetInTouch'>
            <Wrapper>
                <Section color="darkBlue">
                    <h3> Ready to start? </h3>

                    <p> Fill out the form below and we'll be in touch with you soon.</p>

                    <form id='contact-form' onSubmit={handleSubmit}>
                        <label> Name
                            <input name='name' type='text'>
                            </input>
                        </label>
                        <label> Phone
                            <input name='phone' type='text'>
                            </input>
                        </label>
                        <label> Email
                            <input name='email' type='text'>
                            </input>
                        </label>
                        <label>
                        What is the best way to contact you?
                            <div>
                                <select name='contactMethod' defaultValue='None'>
                                    <option disabled value='None'>Select One</option>
                                    <option value='Email'>Email</option>
                                    <option value='Phone'>Phone</option>
                                </select>
                            </div>
                        </label>
                        <label>
                        Which generally describes the type of services you need?
                            <div>
                                <select name='serviceNeed' defaultValue='None'>
                                    <option disabled value='None'>Select One</option>
                                    <option value='New Website'>I'd like to design a new website.</option>
                                    <option value='Upgrade Existing'>I have an existing website that needs refreshed, upgraded or general maintenance.</option>
                                    <option value='Hosting Migration'>I'd like to try and reduce my costs.</option>
                                    <option value='SEO and Socials'>I'd like to increase my visibility using tools like social media and search engines.</option>
                                    <option value='eCommerce Need'>I need a solution to help sell a product or service on my website.</option>
                                    <option value='Other'>I have a different need that isn't listed here.</option>
                                </select>
                            </div>
                        </label>
                        <label> Message
                            <textarea name='message' rows='4' cols='60'>
                            </textarea>
                        </label>
                        <Button onSubmit={handleSubmit}>
                            Submit
                        </Button>
                    </form>
                    <div id='form-success'>
                        <p>Your message has been sent. We'll be in touch as soon as possible.</p>
                    </div>
                    <div id='form-failure'>
                        <p>This is awkward. There was an issue sending your message. We've been notified and will fix the issue as soon as possible. As an alternative, you can reach out and contact me by clicking <a href="mailto:george@digitalwrenchmedia.com">here</a>.</p>
                    </div>
                </Section>
            </Wrapper>
        </div>
    )
}

export default GetInTouch;