export default class ContactForm
{
    constructor(name, phone, email, contactMethod, serviceNeed, message)
    {
        this.name = name;
        this.phone = phone;
        this.email = email;
        this.contactMethod = contactMethod;
        this.serviceNeed = serviceNeed;
        this.message = message;
    }
}

/*
{
    "name": "George Kochera",
    "phone": "(203) 233-9280",
    "email": "george@digitalwrenchmedia.com",
    "contactMethod": "Email",
    "serviceNeed": "SEO",
    "message": "Hey, this is a JSON test!"
}

    "TargetArn": "arn:aws:sns:us-east-1:306780185324:digital-wrench-media-topic-lead:608f5355-5d8c-4682-a325-e225b05c2b09"
*/