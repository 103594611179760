import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import './Card.css';

function _getIcon(props){
    if (props.iconName == 'screwdriver-wrench') {
        return (<FontAwesomeIcon icon={solid('screwdriver-wrench')} />)
    }
    if (props.iconName == 'compass-drafting') {
        return (<FontAwesomeIcon icon={solid('compass-drafting')} />)
    }
    if (props.iconName == 'robot') {
        return (<FontAwesomeIcon icon={solid('robot')} />)
    }
    if (props.iconName == 'gears') {
        return (<FontAwesomeIcon icon={solid('gears')} />)
    }
}

function _getImage(props){
    if (props.image){
        return (<img className="card-image" src={props.image}/>)
    }
}

function _getBody(props){
    if (!props.children){
        return
    }
    return(<div className="card-body">{props.children}</div>)
}

function _getTitle(props){
    if (props.iconName)
    {
        return (
            <div className="card-header">
                <div className="card-icon">
                    {_getIcon(props)}
                </div>
                <div className="card-title">
                    {props.title}
                </div>
            </div>
        )
    }

    return (
        <div className="card-header">
            <div className="card-title">
                {props.title}
            </div>
        </div>
    )
}

function Card(props) {
    if (props.link) {
        return (
            <a href={props.link}>
                <div className='card'>
                    {_getTitle(props)}
                    {_getImage(props)}
                    {_getBody(props)}
                </div>
            </a>
        )
    }
    return (
        <div className='card'>
            {_getTitle(props)}
            {_getImage(props)}
            {_getBody(props)}
        </div>
    )
}

export default Card;