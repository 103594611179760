import React from "react";
import { CardCollection, Section, Wrapper, Card } from "./components";

function Clients() {
    return (
        <div className='Clients'>
            <Wrapper>
                    <Section>
                        <h3>
                            Clients
                        </h3>
                        <p>A collection of current clients I perform services for.</p>

                        <CardCollection>
                            <Card title='Five Churches Brewing' image='portfolio/five-churches.png' link='https://www.fivechurchesbrewing.com' />
                            <Card title='Looking Glass Therapy' image='portfolio/looking-glass.png' link='https://www.lookingglasstherapyct.com' />
                        </CardCollection>
                    </Section>
            </Wrapper>
        </div>
    )
}

export default Clients;