import React from "react";
import './Jumbotron.css';
import { Link } from "react-router-dom";
import Button from "./Button";

function Jumbotron() {
    return (
        <div className='jumbotron'>
            <img className='jumbotron-background-image' src='./backgrounds/digital-wrench.jpg'/>
            <div className='jumbotron-content'>
                <h1 className='jumbotron-header'>
                    Let's <span className='text-blue'>build</span> something great together!
                </h1>
                <p className='jumbotron-body'> 
                    Digital Wrench Media can help you build a simple website or complex web application.
                </p>
                <Button destination="/contact">Get Started Now</Button>
            </div>
        </div>
    )
}

export default Jumbotron;