import React from "react";
import './Timeline.css';

function Timeline(props) {
    return (
        <div className='timeline'>
            <div className='timeline-element'>
                <div className='timeline-element-wrapper'>
                    <div className='timeline-description' />

                    <div className='timeline-image-line'>
                            <div className='tl top'>
                                <div className='tl left'>

                                </div>
                                <div className='tl right'>

                                </div>
                            </div>
                            <div className='tl bottom'>
                                <div className='tl left'>

                                </div>
                                <div className='tl right'>
                                    <div className='timeline-image-wrapper'>
                                        <img alt='US-army-logo' className='timeline-image' src='./us-army.png'></img>
                                    </div>
                                </div>
                            </div>
                        </div>

                    <div className='timeline-description'>
                        <h3>2007</h3>
                        <p>Joined the United States Army Reserve under MOS 31B - Military Police</p>
                    </div>
                </div>
            </div>

            <div className='timeline-separator'>
                <div className='timeline-path' />
                <div className='timeline-path' />
            </div>

            <div className='timeline-element'>
                <div className='timeline-element-wrapper'>

                    <div className='timeline-description'>
                        <h3>2010</h3>
                        <p>Graduated from University of New Haven with a BS in Criminal Justice</p>
                    </div>
                    <div className='timeline-image-line'>
                        <div className='tl top'>
                            <div className='tl left'>

                            </div>
                            <div className='tl right'>

                            </div>
                        </div>
                        <div className='tl bottom'>
                                <div className='tl left'>

                                </div>
                                <div className='tl right'>
                                    <div className='timeline-image-wrapper'>
                                        <img alt='university-of-new-haven-logo' className='timeline-image' src='./university-of-new-haven.png'></img>
                                    </div>
                                </div>
                            </div>
                    </div>

                    <div className='timeline-description' />
                </div>
            </div>

            <div className='timeline-separator'>
                <div className='timeline-path' />
                <div className='timeline-path' />
            </div>

            <div className='timeline-element'>
                <div className='timeline-element-wrapper'>
                    <div className='timeline-description' />

                    <div className='timeline-image-line'>
                            <div className='tl top'>
                                <div className='tl left'>

                                </div>
                                <div className='tl right'>

                                </div>
                            </div>
                            <div className='tl bottom'>
                                <div className='tl left'>

                                </div>
                                <div className='tl right'>
                                    <div className='timeline-image-wrapper'>
                                        <img alt='lockheed-martin-logo' className='timeline-image' src='./lockheed-martin.png'></img>
                                    </div>
                                </div>
                            </div>
                        </div>

                    <div className='timeline-description'>
                        <h3>2012</h3>
                        <p>Hired as a Security Officer at Sikorsky Aircraft in Stratford, CT</p>
                    </div>
                </div>
            </div>

            <div className='timeline-separator'>
                <div className='timeline-path' />
                <div className='timeline-path' />
            </div>

            <div className='timeline-element'>
                <div className='timeline-element-wrapper'>

                    <div className='timeline-description'>
                        <h3>2021</h3>
                        <p>Graduated from Oregon State University with a BS in Computer Science</p>
                    </div>
                    <div className='timeline-image-line'>
                            <div className='tl top'>
                                <div className='tl left'>

                                </div>
                                <div className='tl right'>

                                </div>
                            </div>
                            <div className='tl bottom'>
                                <div className='tl left'>

                                </div>
                                <div className='tl right'>
                                    <div className='timeline-image-wrapper'>
                                        <img alt='oregon-state-university-logo' className='timeline-image' src='./oregon-state.png'></img> 
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='timeline-description' />
                </div>
            </div>
        </div>
    )
}

export default Timeline;