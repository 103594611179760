import { buildQueries } from "@testing-library/react";
import { calculateNewValue } from "@testing-library/user-event/dist/utils";
import React from "react";
import './Section.css';

function Section(props) {
    var classes = ["section"];
    switch (props.color) {
        case "blue":
            classes.push("bg-blue");
            classes.push("text-white");
            break;
        case "darkBlue":
            classes.push("bg-dark-blue");
            classes.push("text-white");
            break;
        case "yellow":
            classes.push("bg-yellow")
            classes.push("text-black");
            break;
        case "brown":
            classes.push("bg-brown");
            classes.push("text-black"); 
            break;
        case "black":
            classes.push("bg-black");
            classes.push("text-white");
            break;
        default:
            classes.push("bg-white");
            classes.push("text-black");

    }
    return (
        <div className={classes.join(" ")}>
            {props.children}
        </div>
    )
}

export default Section;