import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro' 
import { Wrapper, Section, CardCollection, Card, Button } from "./components"
import { Link } from "react-router-dom";
import "./components/"

function OurProcess() {
    return (
        <div className='OurProcess'>
            <Wrapper>
                <Section color="white">
                    <h2>
                        Getting Started 
                    </h2>
                    <p>
                        Every client has their own unique needs and requirements. We always start with a consultation, that takes about an hour in order to understand what you are looking to accomplish.
                    </p>
                </Section>
                <Section color="darkBlue">
                    <h2>
                        From Start to Finish
                    </h2>
                    <p>
                        From our first discussion through the end of the project, you can expect regular, clear communication from me.
                    </p>
                    <h4>
                        Consultation
                    </h4>
                    <p>
                        After you initially reach out, I'll sit down with you and we will go over the specifics of what you are looking for. Part of this discussion will help me learn about you and your company or idea. We will talk about the scale of the project, specific requirements you have and set a timeline.
                    </p>
                    <p>
                        During our meeting, I'll ask you a variety of questions about your goals for the project. Some common goals clients have are: trying to get more visibilty for their brand, opening an new avenue for selling merchandise or desiring to make it easier for new customers to engage with their business.
                    </p>
                    <p>
                        Other topics such as theme, color palette, graphic design needs, and photography requirements are discussed as well. My average intake consultation takes about an hour but can be longer depending on the scope of the project. I'm flexible and our meetings can always be scheduled when they're convenient for you.
                    </p>
                    <h4>
                        Estimate
                    </h4>
                    <p>
                        Before work starts, I will provide you with a written estimate outlining the major parts of the project along with the associated costs to complete each item. Once you approve the estimate, work will commence.
                    </p>
                    <h4>
                        Begin Work
                    </h4>
                    <p>
                        Equipped with the knowledge from our initial consultation, work begins. For larger projects, I'll create a draft version that you'll review and provide feedback on where adjustments can be made, if necessary. For smaller tasks, I'll demo the new feature or enhancement for your sign-off.
                    </p>
                    <h4>
                        Completion and Maintenance
                    </h4>
                    <p>
                        Once we've agreed on the final result of the project, it's time to make it live! I'll take care of all the details getting our new (or updated) project deployed. Customers will have the option as well of continued maintenance and periodic content updates if they so choose. See <em>Payments and Pricing </em> below for more details.
                    </p>
                </Section>
                <Section color="blue">
                    <h2>
                        What We Can Do
                    </h2>

                    <CardCollection>
                        <Card iconName='compass-drafting' title='Design & Development'>
                            We can build sites and applications from the ground up. Need a simple website that allows your customers to see what you do and how to get in touch with you? Need a more complex application that allows your clients to view your products or an eCommerce solution that allows you to leverage the power of the internet to sell?
                        </Card>

                        <Card iconName='screwdriver-wrench' title='Overhaul'>
                            Sometimes your existing website can get dated, or stop working. We can get you back up and running again. Contact form broken? Customers getting error messages? Site doesn't work well on mobile devices? We will perform an in-depth review of your existing project and help you get going again.
                        </Card>

                        <Card iconName='gears' title='Maintenance'>
                            We can monitor the health of your site and keep it secure by periodically ensuring it's components are up to date. 
                        </Card>

                        <Card iconName='robot' title='Automation'>
                            Does your site's content periodically change? Do you have to manually change it each time? There's likely an easier way. We can evaluate your specific scenario to determine how you might be able to automate content updates, saving you time.    
                        </Card>
                    </CardCollection>
                </Section>
                <Section color="white">
                    <h2>
                        Pricing
                    </h2>
                    <p>
                        The total cost of a project is influence by a variety of factors such as its complexity, additional customizations and the cost of any services or licenses for the finished product. Every customer is unique.
                    </p>
                    <h4>
                        Single Projects
                    </h4>
                    <p>
                        Some customers might want to manage their own infrastructure such as hosting, domain name and email setup. I'm happy to support projects where the customer just needs a website designed and initially deployed without ongoing maintenance needs. I will need to know what this infrastructure looks like in order to design a site it works with.
                    </p>
                    <h4>
                        Routine Maintenance and Content Management
                    </h4>
                    <p>
                        If you don't want to handle setting up hosting, obtaining a domain name, or setting up services like email, no problem. I offer maintenance plans to cover this.
                    </p>
                    <p>
                        Additionally, if you don't want to handle content updates on your site and want to be able to simply dictate minor changes (text, images, tables) and have them performed for you, I can provide that as well.
                    </p>
                </Section>
                <Section color="white">
                    <Link to="/contact">
                        <Button>
                            Let's Get Started!
                        </Button>
                    </Link>
                </Section>

            </Wrapper>
        </div>
    )
}

export default OurProcess;